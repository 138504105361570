<template>
  <div class="numeric-input__wrapper">
    <label v-if="label" class="numeric-input__label">{{ label }}</label>
    <input
      ref="input"
      class="numeric-input"
      type="text"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      :readOnly="readOnly"
      @input="updateVModel"
    />
    <div v-if="!disabled" class="numeric-input__buttons">
      <button class="numeric-input__button" :tabindex="-1" @click="plus">
        &#9650;
      </button>
      <button class="numeric-input__button" :tabindex="-1" @click="minus">
        &#9660;
      </button>
    </div>
  </div>
</template>

<script>
import AutoNumeric from 'autonumeric/dist/autoNumeric.min';

const defaultOptions = {
  digitGroupSeparator: ' ',
  decimalCharacter: '.',
  allowDecimalPadding: 'floats',
  emptyInputBehavior: 'null',
  modifyValueOnWheel: false,
};
export default {
  name: 'smNumericInput',

  props: {
    value: {
      type: [String, Number, null],
      required: false,
      default: '',
    },

    options: {
      type: [Object, String, Array],
      required: false,
      default() {
        return defaultOptions;
      },
    },

    resetOnOptions: {
      type: Boolean,
      required: false,
      default: true,
    },

    label: {
      type: String,
      required: false,
    },

    placeholder: {
      type: String,
      required: false,
    },

    name: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    step: {
      type: [Number, String],
      requird: false,
      default: 1,
    },
  },

  data() {
    return {
      anElement: null,
      initialOptions: null,
      hasContentEditable: true,
    };
  },

  computed: {
    anInfo() {
      return {
        value: this.value,
        options: this.options,
      };
    },
  },

  watch: {
    anInfo(newValue, oldValue) {
      if (
        oldValue.options &&
        JSON.stringify(newValue.options) !== JSON.stringify(oldValue.options)
      ) {
        if (this.resetOnOptions) {
          this.anElement.options.reset();
        }

        let optionsToUse;
        if (Array.isArray(newValue.options)) {
          optionsToUse = AutoNumeric.mergeOptions(newValue.options);
        } else {
          optionsToUse = AutoNumeric._getOptionObject(newValue.options);
        }

        this.anElement.update(optionsToUse);
      }

      if (
        newValue.value !== void 0 &&
        this.anElement.getNumber() !== newValue.value &&
        newValue.value !== oldValue.value
      ) {
        this.anElement.set(newValue.value);
      }
    },
  },

  created() {
    if (Array.isArray(this.options)) {
      let optionObjects = {};
      this.options.forEach((optionElement) => {
        this.initialOptions = this.manageOptionElement(optionElement);
        optionObjects = Object.assign(optionObjects, this.initialOptions);
      });

      this.initialOptions = optionObjects;
    } else {
      this.initialOptions = this.manageOptionElement(this.options);
    }

    this.hasContentEditable = !this.initialOptions.readOnly;
  },

  mounted() {
    this.anElement = new AutoNumeric(this.$refs.input, this.initialOptions);
    if (this.value !== null && this.value !== '') {
      this.anElement.set(this.value);
      this.updateVModel();
    }
  },

  methods: {
    updateVModel(event) {
      if (this.anElement !== null) {
        this.$emit('input', this.anElement.getNumber(), event);
      }
    },

    manageOptionElement(optionElement) {
      let options;
      if (
        typeof optionElement === 'string' ||
        optionElement instanceof String
      ) {
        options = AutoNumeric.getPredefinedOptions()[optionElement];
        if (options === void 0 || options === null) {
          options = defaultOptions;
        }
      } else {
        options = optionElement;
      }

      return options;
    },

    plus() {
      const max = this.options.maximumValue;
      const min = this.options.minimumValue;
      const firstValue = this.step >= min ? this.step : min;
      let newValue;

      this.$refs.input.focus();

      if (Number.isInteger(+this.step)) {
        const fllorValue = Math.floor(+this.value);
        newValue = Math.round((fllorValue + this.step) * 10) / 10;
      } else {
        newValue = Math.round((+this.value + this.step) * 10) / 10;
      }

      if (!this.value && this.value !== 0) {
        this.$emit('input', firstValue);
        return;
      }

      if (this.value === max) return;

      this.$emit('input', newValue);
    },

    minus() {
      const min = this.options.minimumValue;
      const firstValue = -this.step >= min ? this.step : min;
      let newValue;

      this.$refs.input.focus();

      if (Number.isInteger(+this.step)) {
        const ceilValue = Math.ceil(+this.value);
        newValue = Math.round((ceilValue - this.step) * 10) / 10;
      } else {
        newValue = Math.round((+this.value - this.step) * 10) / 10;
      }

      if (!this.value && this.value !== 0) {
        this.$emit('input', firstValue);
        return;
      }

      if (this.value === min) return;

      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss">
.numeric-input__wrapper {
  position: relative;

  width: 100%;
}

.numeric-input__label {
  display: inline-block;
  margin-bottom: 3px;

  color: var(--gray);
}

.numeric-input {
  width: 100%;
  height: 36px;
  padding: 7px 12px;

  font-size: 16px;
  line-height: 1;

  border: 1px solid var(--gray);
  border-radius: 10px;

  background-color: var(--white);

  outline: none;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--gray);
  }

  &:focus {
    border: 1px solid var(--blue);
  }

  &:disabled {
    background-color: rgba(var(--rgb-gray), 0.2);

    // cursor: not-allowed;
  }
}

.numeric-input:hover + .numeric-input__buttons,
.numeric-input:focus + .numeric-input__buttons,
.numeric-input__buttons:hover {
  opacity: 1;
}

.numeric-input__buttons {
  z-index: 2;
  position: absolute;
  right: 7px;
  top: 6px;
  display: inline-flex;
  flex-direction: column;
  opacity: 0;
}

.numeric-input__button {
  padding: 0 5px;
  font-size: 12px;
  line-height: 1;
  color: dimgray;
  line-height: 1;
  border: none;
  cursor: pointer;
}

.numeric-input__button:hover {
  background-color: darkgray;
}
</style>
